<template>
    <div class="cs-block cs-showcases-circles-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base" :class="rootClasses">
            <div class="container">
                <div class="header-content">
                    <h2 class="cs-title" v-if="data.title">
                        <ColorSplit :data="data.title" />
                    </h2>
                    <h3 class="cs-sub-title" v-if="data.subTitle">
                        {{ data.subTitle }}
                    </h3>
                    <span v-if="data.text" class="cs-text" v-html="data.text"/>
                </div>
                <div class="cs-cards">
                    <div v-for="(item, key) in data.cards" :key="key" 
                        :class="`cs-card-count-${key} cs-card-column-${key%3} cs-card-row-${(parseInt(key/3)+1)%2}`" 
                        class="cs-card" >

                        <CSButton :data="item.url">
                            <div class="header-area">
                                <img :src="item.image">
                                <h2>{{ item.number }}. {{ item.title }}</h2>
                            </div>
                            <div class="body-area">
                                <span class="cs-text" v-html="item.text"></span>
                            </div>
                        </CSButton>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from '../libs/common-fn';

    export default {
        name: 'PromoPanel',
        components: {
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
            CSButton: () => import("../components/CSButton.vue"),
        },
        props: {
            data: {
                type: Object,
            default: () => {}
            }
        },
        data() {
            return {}
        },
        computed: {
            ...computed('PromoPanel'),
            rootClasses() {
                let result = ''

                if(this.data.textAlign) result+= " cs-align-"+this.data.textAlign;

                return result;
            },
            hasBg() {
                if (this.data.style == 'color') return ' has-background';

                return '';
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../styles/main.scss';
    .cs-block.cs-showcases-circles-block{

        &.cs-style- {
          &color {
            @include cs-block-color;
            .cs-sub-title{
              color:$sub_title_color;
            }
            *, ::v-deep *{
              color:white;
            }
          }

          &light {
            @include cs-block-light;
            .cs-sub-title{
              color: $sub_title_color_light;
            }
          }

          &dark {
            @include cs-block-dark;
            .cs-sub-title{
              color: $sub_title_color_dark;
            }
          }
        }

        .header-content {
            text-align: center;
            margin-bottom: $header_margin;
            &:last-child {
                margin-bottom: 0;
            }
            .cs-text {
                ::v-deep {
                    * {
                        text-align: center;
                    }
                    p,
                    span {
                        @include text;
                    }
                    *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .cs-cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: 1fr;
            grid-row-gap: 30px;

            @media screen and (max-width: 991px) {
                grid-template-columns: repeat(1, 1fr);
                grid-auto-rows: 1fr;
                grid-row-gap: 30px;

                .cs-card{
                    margin:0px !important;
                    padding:15px !important;
                }
            }
        
            .cs-card{

                &.cs-card-row-0{
                    border-top-right-radius:15px;
                    border-bottom-right-radius:15px;
                    &.cs-card-column-0{
                        background: linear-gradient(270deg, #00A983 0%, #2BD784 100%);
                        z-index: 3;
                        padding: 15px;
                        -webkit-box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.1);
                        -moz-box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.1);
                        box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.1);
                        *{
                            color:white
                        }
                    }
                    &.cs-card-column-1{
                        background: linear-gradient(90deg, #FFE3C2 0%, #FEB22E 100%);
                        z-index: 2;
                        margin-left: -15px;
                        padding: 15px 15px 15px 30px;
                        -webkit-box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.1);
                        -moz-box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.1);
                        box-shadow: 5px 0px 15px 0px rgba(0,0,0,0.1);
                    }
                    &.cs-card-column-2{
                        background: linear-gradient(90deg, #3979B7 0.01%, #0D263E 100%);
                        z-index: 1;
                        margin-left: -15px;
                        padding: 15px 15px 15px 30px;
                        *{
                            color:white
                        }
                    }
                }
                &.cs-card-row-1{
                    border-top-left-radius:15px;
                    border-bottom-left-radius:15px;
                    &.cs-card-column-0{
                        background: linear-gradient(90deg, #3979B7 0.01%, #0D263E 100%);
                        padding: 15px 30px 15px 15px;
                        *{
                            color:white
                        }
                    }
                    &.cs-card-column-1{
                        background: linear-gradient(90deg, #FFE3C2 0%, #FEB22E 100%);
                        margin-left: -15px;
                        padding: 15px 30px 15px 15px;
                        -webkit-box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.1);
                        -moz-box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.1);
                        box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.1);
                    }
                    &.cs-card-column-2{
                        background: linear-gradient(270deg, #00A983 0%, #2BD784 100%);
                        margin-left: -15px;
                        padding: 15px;
                        -webkit-box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.1);
                        -moz-box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.1);
                        box-shadow: -5px 0px 15px 0px rgba(0,0,0,0.1);
                        *{
                            color:white
                        }
                    }
                }
                ::v-deep .cs-button-container .cs-button {
                    color: inherit;
                    text-decoration: none;
                
                    .header-area {

                        display: flex;
                        align-items: center;
                        margin: 10px 0 20px 0;
                        
                        img {
                            width: 50px;
                            height: 50px;
                            object-fit: cover;
                            border-radius: 50%;
                            margin-right: 20px;
                        }
                        h2 {
                            font-size: 1rem;
                            font-weight: 500;
                            margin: 0;
                        }
                    }
                    .body-area {
                        margin-bottom: 10px;
                        font-weight: 300;
                    }
                }

            }
        }
    }

</style>
